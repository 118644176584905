@tailwind base;
@tailwind components;
@tailwind utilities;

.bottom_menu_wrapper{
  margin: 0 auto;
  width: 70%;
}
.menu__wrapper{
  box-shadow: 9px 16px 20px 13px hsl(0deg 0% 0% / 14%);
  padding: 20px 0;
  text-align: center;
  margin: 0 auto;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.form_icon{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form_icon img{
  width: 30px;
  height: auto;
padding-bottom: 5px;
}
.form_icon p{
  font-size: 15px;
  font-weight: 400;
}


.form__download{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  padding: 20px 0;
  border-top: 1px solid #000;
}
.title{
  padding: 20px 10px;
  text-transform: uppercase;
  font-weight: 700;
  color: red;
  font-size: 20px;
}
.form__download p{
  font-size: 16px;
  color: #000;
  font-weight: 400;
}
.form__download a{
  text-transform: lowercase;
  font-size: 13px;
}

#headingOne button, #headingTwo button, #headingThree button{
  font-size: 16px;
  color: #000;
  font-weight: 400;
}

.section_container{
  padding: 0 15px;
  max-width: 90% !important;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .grid-cols-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
  .bottom_menu_wrapper{
    width: 100%;
  }
  .form_icon p {
   font-size: 11px;
  }
}